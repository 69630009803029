<template>
    <div>
        <UiFormInput
            :id="id"
            :name="name"
            :label="label"
            :type="type"
            :placeholder="placeholder"
            :model-value="modelValue"
            :error-message="errorMessage"
            @update:model-value="emit('update:modelValue', $event)"
            @focus="() => emit('focus')"
            @blur="() => emit('blur')"
        />
    </div>
</template>

<script setup lang="ts">
defineProps<{
    id?: string;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
    modelValue?: string;
    errorMessage?: string | Ref<string>;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: any): void;
    (e: 'focus'): void;
    (e: 'blur'): void;
}>();
</script>

<style lang="postcss" scoped>
:deep(.form-input) {
    @apply py-1 flex flex-col text-[#293f4d];

    .input-label {
        @apply py-2 text-sm block;
    }
    .form-element {
        @apply w-full block h-12 md:h-10 rounded px-3 py-1.5 bg-white text-sm;
        border: 1px solid #aebfd1;
        transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        &:focus {
            @apply border-[#e62571] text-[#e62571] outline-none shadow-none;
        }
        &.has-error {
            @apply border-error;
        }
    }
    .error-message {
        @apply text-xs text-error;
    }
}
</style>
